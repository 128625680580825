import React, { useState } from 'react';
import Swal from 'sweetalert2';

function Contact() {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Simulación del envío del formulario con fetch o cualquier lógica real de envío
    fetch('https://formsubmit.co/hola@inkspire.digital', {
      method: 'POST',
      body: new FormData(e.target),
    })
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            title: 'Éxito!',
            text: 'Tu mensaje fue enviado correctamente.',
            icon: 'success',
            confirmButtonText: 'Aceptar',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: 'Hubo un problema al enviar tu mensaje. Inténtalo nuevamente.',
            icon: 'error',
            confirmButtonText: 'Aceptar',
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error!',
          text: 'Hubo un error al enviar el formulario. Inténtalo más tarde.',
          icon: 'error',
          confirmButtonText: 'Aceptar',
        });
      })
      .finally(() => {
        setIsSubmitting(false); // Resetear el estado de envío
      });
  };

  return (
    <section id="contact" className="py-5">
      <div className="container">
        <h2 className="text-center mb-4">Contacto</h2>
        <form
          action="https://formsubmit.co/hola@inkspire.digital"
          method="POST"
          onSubmit={handleSubmit}
        >
          {/* Campo oculto para evitar SPAM */}
          <input type="hidden" name="_captcha" value="false" />

          {/* Campo oculto para redirigir al usuario después de enviar el formulario */}
          <input type="hidden" name="_next" value="/" />

          <div className="mb-3">
            <label className="form-label">Nombre</label>
            <input
              type="text"
              name="name"
              className="form-control"
              placeholder="Tu nombre"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Correo Electrónico</label>
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder="tuemail@ejemplo.com"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Teléfono</label>
            <input
              type="tel"
              name="phone"
              className="form-control"
              placeholder="XXXXXXXXXX"
              required
              pattern="^[0-9]{10}$"  // Acepta solo números de 10 dígitos
              title="Solo se permiten números de 10 dígitos"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Mensaje</label>
            <textarea
              name="message"
              className="form-control"
              rows="4"
              placeholder="Escribe tu mensaje"
              required
            ></textarea>
          </div>
          <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
            {isSubmitting ? 'Enviando...' : 'Enviar'}
          </button>
        </form>
      </div>
    </section>
  );
}

export default Contact;