import React, { useState } from 'react';
import { ImageList, ImageListItem, Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const itemData = Array.from({ length: 18 }, (_, index) => ({
  img: `/img${index + 1}.jpeg`,
  title: `Diseño ${index + 1}`
}));

function Diseños() {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  // Abre el overlay con la imagen seleccionada
  const handleImageClick = (img) => {
    setSelectedImage(img);
    setOpen(true);
  };

  // Cierra el overlay
  const handleClose = () => {
    setOpen(false);
    setSelectedImage('');
  };

  return (
    <div>
      <video src="/vid1.mp4" autoPlay loop muted playsInline className="m-auto d-block w-50"></video>
      <ImageList variant="masonry" cols={3} gap={11}>
        {itemData.map((item) => (
          <ImageListItem key={item.img} onClick={() => handleImageClick(item.img)}>
            <img
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.img}?w=248&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
              className="border"
            />
          </ImageListItem>
        ))}
      </ImageList>

      <Dialog open={open} onClose={handleClose} fullScreen>
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background_color: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000, 
          }}
        >
          <img
            src={selectedImage}
            alt="Vista Completa"
            style={{
              maxWidth: '100%',
              maxHeight: '100vh',
              objectFit: 'contain', 
              display: 'block',
              backgroundColor: 'transparent',
            }}
          />
          <IconButton
            onClick={handleClose}
            style={{
              position: 'absolute',
              top: 20,
              right: 20,
              color: 'black',
            }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>
      </Dialog>
    </div>
  );
}

export default Diseños;
