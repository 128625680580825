import React from 'react';

function Services() {
  return (
    <section id="services" className="bg-light py-5">
      <div className="container">
        <h2 className="text-center mb-4">Nuestros Servicios</h2>
        <div className="row">
          <div className="col-md-4 text-center">
            <i className="fas fa-envelope fa-3x mb-3"></i>
            <h4>Invitaciones Personalizadas</h4>
            <p>Creamos diseños únicos para tus eventos especiales.</p>
          </div>
          <div className="col-md-4 text-center">
            <i className="fas fa-scroll fa-3x mb-3"></i>
            <h4>Pancartas y Carteles</h4>
            <p>Pancartas de alta calidad para destacar en cualquier ocasión.</p>
          </div>
          <div className="col-md-4 text-center">
            <i className="fas fa-laptop fa-3x mb-3"></i>
            <h4>Diseños Digitales</h4>
            <p>Contenido visual exclusivo para redes sociales y más.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;