import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function Portfolio() {
  const images = Array.from({ length: 6 }, (_, i) => ({
    src: `/img${i + 1}.jpeg`,
    alt: `Portfolio Item ${i + 1}`,
  }));

  const groupedImages = [];
  for (let i = 0; i < images.length; i += 3) {
    groupedImages.push(images.slice(i, i + 3));
  }

  return (
    <section id="portfolio" className="py-5">
      <Carousel interval={3000} controls={true} indicators={true}>
        {groupedImages.map((group, index) => (
          <Carousel.Item key={index}>
            <div className="d-flex justify-content-center">
              {group.map((image, idx) => (
                <img
                  key={idx}
                  className="mx-2"
                  src={image.src}
                  alt={image.alt}
                  style={{ width: '30%', height: 'auto', objectFit: 'cover' }}
                />
              ))}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="text-center mt-4">
        <a href="/Diseños" className="btn btn-primary">
          Ver más diseños
        </a>
      </div>
    </section>
  );
}

export default Portfolio;