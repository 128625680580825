import React from 'react';

function About() {
  return (
    <section id="about" className="py-5">
      <div className="container">
        <h2 className="text-center mb-4">Acerca de Nosotros</h2>
        <p>
          En Inkspire, nos especializamos en diseñar productos digitales únicos para tus eventos especiales. Ya sea que necesites un diseño para imprimir o solo digital, estamos aquí para transformar tus ideas en obras de arte.
        </p>
      </div>
    </section>
  );
}

export default About;