import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function Navbar() {
  const handleNavLinkClick = () => {
    const navbarToggle = document.getElementById('navbarNav');
    if (navbarToggle.classList.contains('show')) {
      navbarToggle.classList.remove('show');
    }
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <a className="navbar-brand" href="/#home"><h1>Inkspire</h1></a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link btn btn-primary" aria-current="page" href="/#home" onClick={handleNavLinkClick}><h5>Inicio</h5></a>
            </li>
            <li className="nav-item">
              <a className="nav-link btn btn-primary" href="/#about" onClick={handleNavLinkClick}><h5>Sobre Nosotros</h5></a>
            </li>
            <li className="nav-item">
              <a className="nav-link btn btn-primary" href="/#services" onClick={handleNavLinkClick}><h5>Servicios</h5></a>
            </li>
            <li className="nav-item">
              <a className="nav-link btn btn-primary" href="/contacto" onClick={handleNavLinkClick}><h5>Contacto</h5></a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;