import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar';
import Hero from './components/Hero';
import About from './components/About';
import Services from './components/Services';
import Portfolio from './components/Portfolio'; 
import Contact from './components/Contact';
import Diseños from './components/Diseños'; 
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; 
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useEffect } from 'react';


function App() {
  useEffect(() => {
    // Verificamos si hay un hash en la URL
    if (window.location.hash) {
      const sectionId = window.location.hash.substring(1);
      const section = document.getElementById(sectionId);
      
      if (section) {
        // Hacemos scroll suave hasta la sección correspondiente
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <Router>
      <Navbar /> 
      
      <Routes>
        <Route path="/" element={
          <>
            <Hero />
            <About />
            <Services />
            <Portfolio />
            <Footer />
          </>
        } />
        <Route path="/diseños" element={
          <>
          <Diseños />
          <Footer />
          </>
        } />
        <Route path="/contacto" element={
          <>
          <Contact />
          <Footer />
          </>
          } />
      </Routes>
    </Router>
  );
}

export default App;