import React from 'react';
import heroImage from '.././hero.webp';

function Hero() {
  // Estilos para el Hero y el Overlay
  const heroStyle = {
    position: 'relative',
    background: `url(${heroImage}) no-repeat center center`,
    backgroundSize: 'cover',
    color: 'white',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  };

  const overlayStyle = {
    content: "''",
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.55)',
    zIndex: 1,
  };

  const containerStyle = {
    position: 'relative',
    zIndex: 2, 
    textAlign: 'center',
  };

  return (
    <section id="hero" style={heroStyle}>
      <div style={overlayStyle}></div>
      <div style={containerStyle}>
        <h1 className="display-4">Inkspire</h1>
        <p className="lead">Diseños que dan vida a tus eventos.</p>
        <a href="#portfolio" className="btn btn-primary btn-lg">Explora nuestros diseños</a>
      </div>
    </section>
  );
}

export default Hero;